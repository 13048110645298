import { makeTheme } from '@theme-ui/css/utils'
import '@fontsource/poppins'

const heading = {
  color: 'text',
  fontFamily: 'heading',
  lineHeight: 'heading',
  fontWeight: 'heading',
}

export const theme = makeTheme({
  breakpoints: ['30em', '48em', '64em'], // 480px, 768px, 1024px
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  fonts: {
    body: 'Verdana, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
    heading: '"Poppins Bold", "Helvetica Neue", sans-serif',
    monospace: 'menlo, monospace',
  },
  fontSizes: [13, 15, 18, 20, 24, 32, 48, 64, 96],
  fontWeights: {
    body: 400,
    heading: 700,
    bold: 700,
    display: 800,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.25,
  },
  colors: {
    text: '#ddc', // '#d6d2c4', // '#ddc',
    background: '#111', // '#d6d2c4', // '#111',
    primary: '#ff5d55', // '#d3273e', // '#ff5d55', // '#ffc845', // '#ffa7a2',
    secondary: '#003b49', // '#a2ffd5', //'#c1e8da', // '#c0c0c0',
    muted: '#1f1f1f',
    modes: {
      light: {
        text: '#000',
        background: '#d6d2c4', // '#fff',
        primary: '#2557DD', // '#07c', //'#D2042D',
        secondary: '#30c',
        muted: '#c0c0c0',
      },
    },
  },
  styles: {
    root: {
      fontFamily: 'body',
      lineHeight: 'body',
      fontWeight: 'body',
    },
    h1: {
      ...heading,
      fontSize: [5, 6, 7],
    },
    h2: {
      ...heading,
      fontSize: 5,
    },
    h3: {
      ...heading,
      fontSize: 4,
    },
    h4: {
      ...heading,
      fontSize: 3,
    },
    h5: {
      ...heading,
      fontSize: 2,
    },
    h6: {
      ...heading,
      fontSize: 1,
    },
    p: {
      color: 'text',
      fontFamily: 'body',
      fontWeight: 'body',
      lineHeight: 'body',
    },
    a: {
      fontFamily: 'heading',
      cursor: 'pointer',
      color: 'currentColor',
      textDecoration: 'none',
      '&:hover': {
        color: 'primary',
        // borderBottomColor: 'primary',
      },
      '&.active': {
        color: 'primary',
        fontWeight: 'bold',
      },
    },
    pre: {
      fontFamily: 'monospace',
      overflowX: 'auto',
      code: {
        color: 'inherit',
      },
    },
    code: {
      fontFamily: 'monospace',
      fontSize: 'inherit',
    },
    table: {
      width: '100%',
      borderCollapse: 'separate',
      borderSpacing: 0,
    },
    th: {
      textAlign: 'left',
      borderBottomStyle: 'solid',
      borderBottomColor: 'text',
      paddingLeft: 2,
      // mx: 1,
    },
    tbody: {
      // mx: 2,
    },
    tr: {
      px: 1,
      selectRow: {
        cursor: 'pointer',
        '&:hover': {
          bg: 'secondary',
        },
      },
    },
    td: {
      textAlign: 'left',
      verticalAlign: 'center',
      borderBottom: '1px solid',
      borderBottomColor: 'muted',
      py: 1,
      paddingLeft: 2,
      mx: 1,
    },
    img: {
      maxWidth: '100%',
    },
  },
  text: {
    caps: {
      textTransform: 'uppercase',
      letterSpacing: '.2em',
    },
    heading: {
      fontFamily: 'heading',
      fontWeight: 'heading',
      lineHeight: 'heading',
    },
    display: {
      // extends the text.heading styles
      variant: 'text.heading',
      fontSize: [7, 8],
      fontWeight: 'display',
      letterSpacing: '-0.012em',
      lineHeight: 1,
    },
  },
  cards: {
    primary: {
      padding: 2,
      borderRadius: 4,
      boxShadow: '0 0 2px 1px rgba(0, 0, 0, 0.5)',
    },
  },
  buttons: {
    primary: {
      cursor: 'pointer',
      fontWeight: 'bold',
      color: 'text',
      bg: 'primary',
      '&:hover': {
        color: 'primary',
        bg: 'text',
      },
    },
    secondary: {
      fontSize: '13px',
      fontWeight: 'bold',
      color: 'background',
      bg: 'gold',
      '&:hover': {
        color: 'text',
        bg: 'muted',
      },
    },
  },
  badges: {
    primary: {
      color: 'secondary',
      bg: 'primary',
    },
    outline: {
      color: 'primary',
      bg: 'transparent',
      boxShadow: 'inset 0 0 0 1px',
    },
  },
})

export default theme
